<template>
  <div>
    <p class="text-center font-weight-bolder font-large-1">
      {{ $t('Create User') }}
    </p>
    <user-form
      ref="user-form"
      :is-create-form="true"
    />
  </div>
</template>

<script>

import userMenuItems from '@/views/components/sub-menu/settings-sub-menu/users'
import UserForm from '../components/UserForm.vue'
import config from '../userConfig'

export default {
  name: 'UserCreate',
  components: {
    UserForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['user-form']) {
      this.$refs['user-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    this.$emit('updateMenu', 'user-list')
    this.$store.commit(`${this.MODULE_NAME}/SET_USER_FORM`, {
      status: 'active',
      phone: '',
    })
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_USER_FORM_CLONE`, {
      status: 'active',
      phone: '',
    })
  },
  setup() {
    const MODULE_NAME = 'users'
    const MODULE_NAME_CLONE = 'cloneData'

    const { menuItems } = userMenuItems('users')
    const { fields } = config()
    return {
      menuItems,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>
